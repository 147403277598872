import { base_url } from '../../../components/Mode';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import CustomBackdrop from '../../CustomBackdrop';
import { Box, Button } from '@mui/material';
import FilterBlocks from './FilterBlocks';
import { currentAdminIsTraineeOrIC } from '../../../utils/common';

const LeadsToAssignedBySalsa = ({ old = false }) => {
  const [nextLeadsToAssigned, setNextLeadsToAssigned] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [leadIdsToExclude, setLeadIdsToExclude] = useState('');

  const getLeadRequestValidationUrl = () => {
    if (Array.isArray(leadIdsToExclude) && leadIdsToExclude.length !== 0) {
      const encodedIds = leadIdsToExclude.join(',');
      return (
        base_url() +
        `/api/salsa/next-lead-assignments?old=${old}&ids=${encodedIds}`
      );
    }
    return base_url() + `/api/salsa/next-lead-assignments?old=${old}`;
  };

  useEffect(() => {
    nextLeadAssignment();
  }, [leadIdsToExclude]);

  const nextLeadAssignment = () => {
    setLoading(true);
    let leadRequestValidationUrl = getLeadRequestValidationUrl();
    if (leadRequestValidationUrl) {
      axios
        .get(leadRequestValidationUrl)
        .then((res) => {
          if (Array.isArray(res.data)) {
            res.data.map((lead) => {
              setNextLeadsToAssigned((prev) => [...prev, lead]);
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorMessage(
            err.response?.data?.message || "Couldn't get all drop request."
          );
          setLoading(false);
        });
    }
  };

  const handleLoadMore = () => {
    if (Array.isArray(nextLeadsToAssigned)) {
      const idToExclude = nextLeadsToAssigned.map((leads) => {
        return leads.id;
      });
      setLeadIdsToExclude(idToExclude);
    }
  };

  const handleRowClick = (event, leadId) => {
    const currentUrl = '/leads/' + leadId;
    if (currentAdminIsTraineeOrIC()) {
      window.location.href = currentUrl;
    } else {
      window.open(currentUrl, '_blank');
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Lead ID',
      width: 150,
    },
    {
      field: 'filters',
      headerName: 'Filters',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', flexDirection: 'row', overflow: 'auto' }}>
          <FilterBlocks filters={params?.row?.filtersScore} />
        </Box>
      ),
    },
  ];

  return (
    <>
      <CustomBackdrop open={{ open: loading }} />
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      <div>
        <h3 style={{ marginBottom: '20px' }}>Leads To Be Assigned By Salsa</h3>

        {Array.isArray(nextLeadsToAssigned) &&
        nextLeadsToAssigned.length > 0 ? (
          <div
            style={{
              width: '100%',
              overflowX: 'auto',
            }}
          >
            <DataGrid
              rowHeight={130}
              autoHeight={true}
              rows={nextLeadsToAssigned}
              columns={columns}
              pageSize={100}
              pagination
              onCellClick={(params) => {
                if (params.field === 'id') {
                  const leadId = params?.row?.id;
                  handleRowClick(params.event, leadId);
                }
              }}
            />
          </div>
        ) : (
          <p>No data to show</p>
        )}
      </div>
    </>
  );
};
export default LeadsToAssignedBySalsa;
