import { Box } from '@mui/material';
import Text from '../pages/Profile/Atoms/Text';
import CustomTextField from '../pages/Profile/Atoms/CustomTextFiled';
import CustomButton from '../pages/Profile/Atoms/CustomButton';
import { base_url } from './Mode';
import axios from 'axios';
import constants from '../utils/constants';
import ErrorNotifier from './ToastNotifications/ErrorNotifier';
import SuccessNotifier from './ToastNotifications/SuccessNotifier';
import { Switch, FormControlLabel } from '@mui/material';
import { currentAdminIsAdmin } from '../utils/common';
const { useState, useEffect } = require('react');

const SalsaLimitSettings = () => {
  const [salsaLimit, setSalsaLimit] = useState(null);
  const [salsaStatus, setSalsaStatus] = useState(false);
  const [edit, setIsEdit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchSalsaSettings();
  }, []);

  const fetchSalsaSettings = () => {
    const url = base_url() + '/api/settings';
    axios
      .get(url)
      .then((res) => {
        setValuesToState(res?.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ?? 'Error while fetching settings.'
        );
      });
  };

  const updatehRequestLeadLimit = () => {
    if (+salsaLimit <= 0 || +salsaLimit > 20) {
      setHasError(true);
      setErrorMessage(
        'Limit can not be less than equal to zero and greater than 20.'
      );
      return;
    }

    const url = base_url() + '/api/settings/salsa-limit';
    axios
      .patch(url, { salsa_limit: salsaLimit })
      .then((res) => {
        setSalsaLimit(res?.data?.updated_limit);
        setIsSuccess(true);
        setSuccessMessage(
          'Limit for requesting lead per IC updated successfully'
        );
        setIsEdit(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ?? 'Error while updating limit'
        );
      });
  };

  const updateSalsaStatus = (changedSalsaStatus) => {
    const url = base_url() + '/api/settings/salsa-status';
    axios
      .patch(url, { salsa_status: changedSalsaStatus })
      .then((res) => {
        console.log(res.data);

        setSalsaStatus(res?.data?.salsa_status);
        setIsSuccess(true);
        setSuccessMessage('Salsa status updated successfully');
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ?? 'Error while updating salsa status'
        );
      });
  };
  const handleLimitChange = (e) => {
    setSalsaLimit(e.target.value);
  };

  const saveDataHandler = async () => {
    updatehRequestLeadLimit();
  };

  const cancelHandler = () => {
    setIsEdit(false);
  };

  const editHandler = async () => {
    setIsEdit(true);
  };

  const handleSalsaStatusChange = () => {
    const updatedSalsaStatus = !salsaStatus;
    setSalsaStatus(updatedSalsaStatus);
    updateSalsaStatus(updatedSalsaStatus);
  };

  const setValuesToState = (data) => {
    try {
      data?.forEach((item) => {
        if (item.key === constants.SALSA_LIMIT_SETTING_KEY) {
          setSalsaLimit(parseInt(item.value, 10));
        } else if (item.key === constants.SALSA_STATUS_SETTING_KEY) {
          setSalsaStatus(item.value === '1');
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <div>
        <Text
          text={'Request Limit Per IC'}
          textStyle1={{
            fontWeight: 'bold',
            fontSize: '17px',
            marginRight: '20px',
          }}
        />
        {!edit && (
          <>
            <Text
              text={salsaLimit}
              textStyle1={{
                fontWeight: 'medium',
                fontSize: '17px',
                marginTop: '7px',
              }}
            />
            <Box>
              <CustomButton
                variant={'contained'}
                style={{ marginTop: '15px' }}
                text={'Edit'}
                clickHandler={editHandler}
              />
            </Box>
          </>
        )}

        {edit && (
          <>
            <CustomTextField
              changeHandler={handleLimitChange}
              value={salsaLimit}
              customAttributes={{
                type: 'number',
                id: 'limit',
                label: 'Enter Request Lead Limit',
                placeholder: 'Enter Limit',
                variant: 'standard',
              }}
              customStyle={{
                color: 'blue',
                width: '50%',
                marginTop: '5px',
              }}
            />
            <Box sx={{ marginTop: '30px' }}>
              <CustomButton
                variant={'contained'}
                style={{ marginRight: '10px' }}
                text={'Save'}
                clickHandler={saveDataHandler}
              />
              <CustomButton
                variant={'contained'}
                text={'Cancel'}
                clickHandler={cancelHandler}
              />
            </Box>
          </>
        )}
      </div>
      {currentAdminIsAdmin() && (
        <div style={{ marginTop: '25px' }}>
          <h3>Salsa Status</h3>
          <FormControlLabel
            control={
              <Switch
                checked={salsaStatus}
                onChange={handleSalsaStatusChange}
                color='primary'
              />
            }
            label={salsaStatus ? 'On' : 'Off'}
          />
        </div>
      )}
    </>
  );
};

export default SalsaLimitSettings;
