import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, redirect, useLocation, useNavigate } from 'react-router-dom';
import { allAdmins, useAllAdmins } from './AdminsContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled, useTheme } from '@mui/material/styles';
import { Avatar } from '@mui/material';
import { keyframes } from '@emotion/react';
import {
  currentAdminIsHr,
  currentAdminIsTa,
  currentAdminIsInterviewer,
  currentAdminIsTraineeOrIC,
} from '../utils/common';

import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  Box,
  Tooltip,
  Chip,
} from '@mui/material';
import { Logo } from '../components/Logo';
import { background, Center, Stack } from '@chakra-ui/react';
import PersonIcon from '@mui/icons-material/Person';
import CreateLeadForm from '../components/users/CreateLeadForm';
import CreateCandidateForm from './hrms/CreateCandidateForm';
import axios from 'axios';
import { base_url } from './Mode';
import ErrorNotifier from './ToastNotifications/ErrorNotifier';
import {
  getCurrentAdminRole,
  slugify,
  getCurrentAdminName,
  currentAdminIsAdmin,
  shouldEnforceFocusMode,
  getCurrentAdminId,
  currentAdminProfilePicture,
} from '../utils/common';
import MenuIcon from '@mui/icons-material/Menu';
import NewReciptModal from './Receipt/NewReciptModal';
import { getSidebarContent } from './SideBarList';
import Logout from './home/Logout';
import constants from '../utils/constants';
import AdminProfileModal from './AdminProfileModal';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminSettingsModal from './AdminSettingsModal';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import {
  currentAdminIsIC,
  currentAdminIsManager,
  currentAdminIsTrainee,
} from './../utils/common';
import { useAuthStateValue } from './auth/AuthContext';
import LeadsLayout from './leads/LeadsLayout';
import Capcount from './capCount/Capcount';
import CustomBadge from '../components/CustomBadge';
import IcStatus from './icStatus/IcStatus';

const blinkAnimation = keyframes`
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  margin: 1rem;
  position: relative;
`;

const drawerWidth = 290;
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export function addDays(to, days) {
  let date = new Date(to);
  date.setDate(date.getDate() + days);
  return date;
}

export function subtractDays(from, days) {
  let date = new Date(from);
  date.setDate(date.getDate() - days);
  return date;
}

// by default, returns time difference in days.
export function getTimePeriod(end, start, inHours = false) {
  const res = (end - start) / (24 * 60 * 60 * 1000);
  if (inHours) {
    return res * 24;
  }
  return Math.floor(res);
}

function getDriveProperties(driveName, driveStartTime, driveEndTime) {
  const now = new Date(); // IST time
  const startTime = new Date(driveStartTime); // in IST
  const endTime = new Date(driveEndTime);

  let properties = {
    color: '',
    driveLabelText: '',
  };

  if (now < startTime) {
    return {};
  }
  if (startTime <= now && now < addDays(startTime, 5)) {
    properties.color = '#388e3c'; // green
    properties.driveLabelText = `${driveName} drive has started`;
    return properties;
  }
  if (addDays(startTime, 5) <= now && now < subtractDays(endTime, 10)) {
    return {};
  }
  if (subtractDays(endTime, 10) <= now && now < subtractDays(endTime, 3)) {
    properties.color = 'blue'; // blue
    properties.driveLabelText = `${driveName} drive ends in ${getTimePeriod(
      endTime,
      now
    )} days`;
    return properties;
  }
  if (subtractDays(endTime, 3) <= now && now < subtractDays(endTime, 1)) {
    properties.color = '#f57c00'; // orange
    properties.driveLabelText = `${driveName} drive ends in ${getTimePeriod(
      endTime,
      now
    )} days`;
    return properties;
  }
  if (subtractDays(endTime, 1) <= now && now <= endTime) {
    properties.color = '#d32f2f'; // red
    const period = getTimePeriod(endTime, now, true);
    if (period >= 1) {
      // greater than 1 hour
      properties.driveLabelText = `${driveName} drive ends in ${Math.floor(
        period
      )} hours`;
    } else if (period >= 0.0166) {
      // greater than 1 minute, but less than 1 hour
      properties.driveLabelText = `${driveName} drive ends in ${Math.floor(
        period * 60
      )} minutes`;
    } else if (period > 0) {
      // greater than 0 seconds, but less than 1 minute
      properties.driveLabelText = `${driveName} drive ends in ${Math.floor(
        period * 60 * 60
      )} seconds`;
    } else {
      properties = {};
    }
    return properties;
  }
  return {};
}

function Sidebar({ open, handleDrawerClose }) {
  const { allAdmins } = useAllAdmins();
  const admins = allAdmins;
  const [drives, setDrives] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openReceiptModal, setOpenReceiptModal] = useState(false);
  const [openSettingModal, setOpenSettingModal] = useState(false);
  const [openCreateLeadForm, setOpenCreateLeadForm] = useState(false);
  const [openCreateCandidateForm, setOpenCreateCandidateForm] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [missingReportsCount, setMissingReportsCount] = useState(0);
  const [missedDnpCount, setMissedDnpCount] = useState(0);
  const [missedRcbCount, setMissedRcbCount] = useState(0);
  const [warningMessage, setWarningMessage] = useState('');
  const currentAdminRoles = getCurrentAdminRole();
  const [capCount, setCapCount] = useState(0);

  let selectedRole = '';

  const navigate = useNavigate();

  const handleprofile = async () => {
    const admin = JSON.parse(localStorage.getItem('admin'));
    const { username } = admin;
    if (username) {
      navigate(`/admin-profile/${username}`);
    } else {
      navigate(`/bucket-search`);
    }
  };

  useEffect(() => {
    fetchDrivesForNotification();
    fetchMissingReportsCount();
    checkBucketSortedStatusCall();
    fetchcapcountinDrive();
    if (currentAdminIsIC() || currentAdminIsTrainee()) {
      fetchMissedDnpCount();
      fetchMissedRcbCount();
    }
    const interval = setInterval(() => {
      checkBucketSortedStatusCall();
    }, 15 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchcapcountinDrive = () => {
    axios
      .get(`${base_url()}/api/get_cap_count_in_a_drive`)
      .then((res) => {
        if (res?.data && res?.data !== undefined && res?.data !== null) {
          setCapCount(res?.data);
        } else {
          setCapCount(0);
        }
      })
      .catch((error) => {
        setHasError(true);
        setErrorMessage('Unable to fetch Total CAPs:', error);
      });
  };
  currentAdminRoles.forEach((role) => {
    if (selectedRole !== '') {
      return;
    }
    if (role === constants.ADMIN) {
      selectedRole = constants.ADMIN;
    } else if (role === constants.MANAGER) {
      selectedRole = constants.MANAGER;
    } else if (role === constants.SENIOR_MANAGER) {
      selectedRole = constants.SENIOR_MANAGER;
    } else if (role === constants.MARKETING) {
      selectedRole = constants.MARKETING;
    } else if (role === constants.PROGRAM_MANAGER) {
      selectedRole = constants.PROGRAM_MANAGER;
    } else if (role === constants.FINANCE) {
      selectedRole = constants.FINANCE;
    } else {
      selectedRole = constants.INDEPENDENT_CONTRIBUTOR;
    }
  });

  const [sidebarMenuList, setSidebarMenuList] = useState(
    getSidebarContent(selectedRole)
  );
  const theme = useTheme();
  const loc = useLocation();

  const styles = {
    flashStyle: {
      color: 'red',
      position: 'relative',
      display: 'inline-block',
      background:
        'linear-gradient(90deg, rgba(251,73,38) 0%, rgba(251,73,38,0.8) 20%, rgba(247,156,82,0.8) 50%, rgba(255,0,0,0.8) 80%, rgba(255,0,0) 100%)',
      backgroundSize: '200px 100%',
      animation: 'flash 2.5s infinite',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontWeight: 'bold',
    },
    keyframesStyle: `
      @keyframes flash {
        0% {
          background-position: -200px 0;
        }
        100% {
          background-position: calc(200px + 100%) 0;
        }
      }
    `,
  };

  function fetchMissingReportsCount() {
    if (currentAdminRoles.includes(constants.ADMIN)) {
      return;
    }
    axios
      .get(`${base_url()}/api/reports?status=missing&type=count`)
      .then((res) => {
        setMissingReportsCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchMissedDnpCount() {
    try {
      const response = await axios.get(`${base_url()}/api/dnp-leads`);
      if (response?.data) {
        const missedDnpLeads = response?.data?.missedDnpLeadDetails || [];
        if (missedDnpLeads.length > 0) {
          setMissedDnpCount(missedDnpLeads.length);
        }
      }
    } catch (err) {
      console.error(err);
      setHasError(true);
      setErrorMessage("Can't fetch the missed DNP count");
    }
  }

  async function fetchMissedRcbCount() {
    try {
      const response = await axios.get(`${base_url()}/api/rcb-leads`);
      if (response?.data) {
        const missedRcbLeads = response?.data?.missedRcbLeadDetails || [];
        if (missedRcbLeads.length > 0) {
          setMissedRcbCount(missedRcbLeads.length);
        }
      }
    } catch (err) {
      console.error(err);
      setHasError(true);
      setErrorMessage("Can't fetch the missed RCB count");
    }
  }

  const handleOnClick = useCallback((name) => {
    name = name.toLowerCase().split(' ').join('_');
    if (name === 'generate_receipt') {
      setOpenReceiptModal(true);
    } else if (name === 'create_lead') {
      setOpenCreateLeadForm(true);
    } else if (name === 'create_candidate') {
      setOpenCreateCandidateForm(true);
    }
  }, []);

  function fetchDrivesForNotification() {
    axios
      .get(`${base_url()}/api/drives/notifications`)
      .then((res) => {
        let result = {};
        const { activeDrive = null, nextDrive = null } = res.data ?? {};

        if (activeDrive) {
          const { name, starts_at, ends_at } = activeDrive;
          result = getDriveProperties(name, starts_at, ends_at);
        }

        if (Object.keys(result).length === 0 && nextDrive) {
          const { name, starts_at, ends_at } = nextDrive;
          result = getDriveProperties(name, starts_at, ends_at);
        }

        if (Object.keys(result).length === 0 && !activeDrive) {
          result = {
            color: 'red',
            driveLabelText: 'Please create a new sales drive',
          };
        }
        setDrives(result);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch the drives.'
        );
      });
  }

  const excludedTrainees = [
    884, 894, 895, 897, 904, 906, 907, 908, 910, 913, 917, 919, 920, 921, 922,
    923, 924, 925, 926, 927, 928, 929, 944,
  ];

  const checkBucketSortedStatusCall = async () => {
    if (
      currentAdminIsTraineeOrIC() &&
      !excludedTrainees.includes(getCurrentAdminId())
    ) {
      try {
        const res = await axios.post(`${base_url()}/api/check-bucket-status`);
        const oldFocusModeStatus = shouldEnforceFocusMode();
        const { enforce_focus_mode = null, message = '' } = res.data;
        if (message !== '') {
          setWarningMessage(message);
          return;
        }

        setWarningMessage('');
        if (oldFocusModeStatus !== enforce_focus_mode) {
          const admin = JSON.parse(localStorage.getItem('admin')) ?? {};
          admin.enforce_focus_mode = enforce_focus_mode;
          localStorage.setItem('admin', JSON.stringify(admin));
          window.location.reload();
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
  // const priorityListUsers = [
  //   866, 896, 829, 769, 758, 681, 678, 386, 276, 898, 232, 744, 748, 718, 649,
  //   780, 773, 766, 645, 658, 320, 667, 666, 417, 365, 444, 688, 468, 714, 668,
  //   472, 913, 905, 870, 892, 890, 908, 884, 917, 897, 914, 907, 904, 902,
  // ];
  const driveLabel = useMemo(() => {
    return (
      <>
        {currentAdminIsIC() && <IcStatus />}
        {Object.keys(drives).length > 0 && (
          <ListItem
            key={'Drive notification'}
            sx={{
              background: drives.color,
              color: 'white',
              fontWeight: 600,
              padding: '0px 10px',
              textAlign: 'center',
            }}
          >
            <ListItemText
              primary={drives.driveLabelText}
              sx={{
                fontSize: '13px',
                fontWeight: 600,
                padding: '5px',
                margin: '0px',
                maxWidth: '230px',
              }}
            />
          </ListItem>
        )}

        {capCount > 0 && (
          <ListItem
            key={'CAP count'}
            sx={{
              background: 'default',
              color: 'black',
              fontWeight: 600,
              padding: '0px 10px',
              textAlign: 'center',
              marginTop: '15px',
            }}
          >
            <Capcount capCount={capCount} />
          </ListItem>
        )}
      </>
    );
  }, [drives, capCount]);

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Drawer
        sx={{
          width: open ? drawerWidth : 0,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'white',
          },
        }}
        variant='persistent'
        anchor='left'
        open={open}
      >
        <Center
          style={{
            paddingTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Stack
            dir='col'
            style={{ marginLeft: '20px' }}
          >
            <Logo
              width={200}
              padding={5}
            />
          </Stack>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <MenuIcon style={{ fontSize: '30px' }} />
            </IconButton>
          </DrawerHeader>
        </Center>
        <br />
        <Divider />
        <List style={{ paddingTop: 0 }}>
          {driveLabel}
          {sidebarMenuList.map((item) => {
            const { links, buttons } = item.subFields;
            return (
              <Box
                pt={1}
                pl={1}
                pr={1}
              >
                <Stack
                  direction='row'
                  justifyContent='space-between'
                >
                  <Typography
                    variant='body1'
                    component='p'
                    gutterBottom={false}
                    sx={{
                      fontSize: '15px',
                      fontWeight: 500,
                      marginTop: '1px',
                      marginBottom: '1px',
                    }}
                  >
                    {item.heading}
                  </Typography>

                  {buttons.length > 0 &&
                    buttons.map((btn) => {
                      const { icon, name } = btn;
                      return (
                        <Tooltip title={name}>
                          <IconButton onClick={() => handleOnClick(name)}>
                            {icon}
                          </IconButton>
                        </Tooltip>
                      );
                    })}
                </Stack>

                {links.map((accessItem) => {
                  const { name, path, icon } = accessItem;

                  // remove all other tabs except priority list for a specific team

                  const removedTabs = [
                    'Hot Leads',
                    'Follow ups due',
                    'RCB',
                    'DNP',
                    'Total',
                  ];

                  if (
                    removedTabs.includes(name) &&
                    currentAdminIsTraineeOrIC()
                  ) {
                    return null;
                  }
                  // if a user is not trainee or IC then remove priority list
                  if (
                    name === 'Priority List' &&
                    !currentAdminIsTraineeOrIC()
                  ) {
                    return null;
                  }

                  return (
                    <StyledLink
                      to={path}
                      style={{
                        margin: '0px',
                      }}
                    >
                      <ListItemButton
                        onClick={() => handleOnClick(name)}
                        key={name}
                        sx={{
                          backgroundColor:
                            loc.pathname === path
                              ? 'rgb(240, 247, 255)'
                              : 'default',
                          color:
                            loc.pathname === path
                              ? 'rgb(0, 114, 229)'
                              : 'default',
                          marginLeft: '15px',
                          padding: 'inherit',
                          marginTop: '5px',
                        }}
                      >
                        {icon && <ListItemIcon>{icon}</ListItemIcon>}
                        <>
                          <style>{styles.keyframesStyle}</style>
                          <ListItemText
                            primary={
                              name === 'Hot Leads' ? (
                                <span style={styles.flashStyle}>{name}</span>
                              ) : (
                                name
                              )
                            }
                          />
                        </>
                        {name.toLowerCase() === 'reports' &&
                          missingReportsCount > 0 && (
                            <Chip
                              label={`${missingReportsCount} missing`}
                              size='small'
                              variant='outlined'
                              sx={{
                                fontSize: '0.9em',
                                color: '#f44336',
                                borderColor: '#f44336',
                              }}
                            />
                          )}
                        {name.toLowerCase() === constants.DID_NOT_PICK &&
                          missedDnpCount > 0 && (
                            <CustomBadge badgeText={missedDnpCount} />
                          )}
                        {name.toLowerCase() === constants.REQUESTED_CALLBACK &&
                          missedDnpCount > 0 && (
                            <CustomBadge badgeText={missedRcbCount} />
                          )}

                        {name.toLowerCase() === 'bucket search' &&
                          (currentAdminIsIC() || currentAdminIsManager()) &&
                          warningMessage !== '' && (
                            <Tooltip title={warningMessage}>
                              <WarningAmberRoundedIcon
                                color='warning'
                                sx={{
                                  fontSize: 30,
                                  filter: 'drop-shadow(0px 3px 2px #ED6C02)',
                                  animation: `${blinkAnimation} 2s infinite`,
                                }}
                              />
                            </Tooltip>
                          )}
                      </ListItemButton>
                    </StyledLink>
                  );
                })}

                <Divider sx={{ marginTop: '1px' }} />
              </Box>
            );
          })}
        </List>

        {!currentAdminIsTraineeOrIC() && (
          <Box
            px={2}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <ListItemIcon>
              <Avatar
                sx={{
                  width: 22,
                  height: 22,
                  cursor: 'pointer',
                }}
                name='profile'
                src={
                  currentAdminProfilePicture() ||
                  'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp'
                }
              />
            </ListItemIcon>
            <ListItemText
              primary={'Profile'}
              onClick={() => {
                handleprofile();
              }}
              sx={{
                cursor: 'pointer',
                marginLeft: '-1rem',
              }}
            />
          </Box>
        )}

        {!currentAdminIsTraineeOrIC() && (
          <Box
            px={2}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              cursor: 'pointer',
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary={'Settings'}
              onClick={(e) => setOpenSettingModal(!openSettingModal)}
              sx={{
                cursor: 'pointer',
                marginLeft: '-1rem',
              }}
            />
          </Box>
        )}

        <Logout />
      </Drawer>

      {openCreateLeadForm && (
        <LeadsLayout
          openModal={openCreateLeadForm}
          setOpenModal={setOpenCreateLeadForm}
        />
      )}

      {openCreateCandidateForm && (
        <CreateCandidateForm
          openModal={openCreateCandidateForm}
          setOpenModal={setOpenCreateCandidateForm}
          userDetails={userDetails}
          updateDetails={false}
          updateUserDetail={setUserDetails}
        />
      )}

      {openSettingModal && (
        <AdminSettingsModal
          {...{
            openSettingModal,
            setOpenSettingModal,
          }}
        />
      )}

      {openReceiptModal && (
        <NewReciptModal {...{ openReceiptModal, setOpenReceiptModal }} />
      )}
    </>
  );
}

export default React.memo(Sidebar);
