import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { base_url } from '../components/Mode';
import LeadsDataTable from '../components/data-table/LeadsDataTable';
import { Tabs, Tab, Box, Chip, Grid } from '@mui/material';
import CustomBackdrop from './CustomBackdrop';
import ErrorNotifier from '../components/ToastNotifications/ErrorNotifier';
import CustomBadge from '../components/CustomBadge';
import moment from 'moment';
import { currentAdminIsTraineeOrIC } from '../utils/common';
import { useNavigate } from 'react-router-dom';

export function DidNotPick() {
  const [activeTab, setActiveTab] = useState(0);
  const [upcomingDnpLeads, setUpcomingDnpLeads] = useState([]);
  const [missedDnpLeads, setMissedDnpLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [upcomingDnpLeadsToday, setUpcomingDnpLeadsToday] = useState([]);
  const [upcomingDnpLeadsTomorrow, setUpcomingDnpLeadsTomorrow] = useState([]);
  const [selectedDay, setSelectedDay] = useState('all');
  const [limit, setLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    if (currentAdminIsTraineeOrIC()) {
      navigate('/leads/priority-list');
    }
  }, []);

  useEffect(() => {
    fetchDnpLeadsData();
  }, []);

  const fetchDnpLeadsData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${base_url()}/api/dnp-leads`);
      if (response?.data) {
        setMissedDnpLeads(response?.data?.missedDnpLeadDetails || []);
        const allUpcomingDnpLeads =
          response?.data?.upcomingDnpLeadDetails || [];
        setUpcomingDnpLeads(allUpcomingDnpLeads);
        const today = moment().startOf('day');
        const tomorrow = moment(today).add(1, 'days');

        const filteredsDnpLeadsToday = allUpcomingDnpLeads.filter((lead) => {
          const snoozeDate = moment(lead.snoozed_until_1);
          return snoozeDate.isSame(today, 'day');
        });
        setUpcomingDnpLeadsToday(filteredsDnpLeadsToday);

        const filteredDnpLeadsTomorrow = allUpcomingDnpLeads.filter((lead) => {
          const snoozeDate = moment(lead.snoozed_until_1);
          return snoozeDate.isSame(tomorrow, 'day');
        });
        setUpcomingDnpLeadsTomorrow(filteredDnpLeadsTomorrow);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setHasError(true);
      setErrorMessage('No Dnp leads data');
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChipClick = (day) => {
    setSelectedDay(day);
  };

  let dataToDisplay;
  switch (selectedDay) {
    case 'today':
      dataToDisplay = upcomingDnpLeadsToday;
      break;
    case 'tomorrow':
      dataToDisplay = upcomingDnpLeadsTomorrow;
      break;
    default:
      dataToDisplay = upcomingDnpLeads;
  }

  function getLeadsLength(day) {
    if (day === 'today') {
      return upcomingDnpLeadsToday.length;
    } else if (day === 'tomorrow') {
      return upcomingDnpLeadsTomorrow.length;
    } else {
      return upcomingDnpLeads.length;
    }
  }

  const getChipProps = (day) => {
    return {
      label: `${day.charAt(0).toUpperCase() + day.slice(1)} (${getLeadsLength(
        day
      )})`,
      variant: selectedDay === day ? 'filled' : 'outlined',
      onClick: () => handleChipClick(day),
      style: {
        backgroundColor: selectedDay === day ? '#1976d2' : '#fff', // Primary color for selected, white for unselected
        color: selectedDay === day ? '#fff' : '#000', // White text for selected, black for unselected
      },
    };
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <CustomBackdrop open={loading} />
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
        >
          <Tab
            label={
              <Box
                display='flex'
                alignItems='center'
              >
                Missed DNP <CustomBadge badgeText={missedDnpLeads.length} />
              </Box>
            }
          />
          <Tab label='Upcoming DNP' />
        </Tabs>
      </Box>
      {activeTab === 0 && (
        <LeadsDataTable
          data={missedDnpLeads}
          isCalledFromDnp={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={limit}
          setPerPage={(entriesPerPage) => setLimit(entriesPerPage)}
        />
      )}
      {activeTab === 1 && (
        <Box>
          <Grid
            container
            spacing={1}
            sx={{ margin: 2 }}
          >
            <Grid item>
              <Chip {...getChipProps('all')} />
            </Grid>
            <Grid item>
              <Chip {...getChipProps('today')} />
            </Grid>
            <Grid item>
              <Chip {...getChipProps('tomorrow')} />
            </Grid>
          </Grid>
          {dataToDisplay.length > 0 && (
            <LeadsDataTable
              data={dataToDisplay}
              isCalledFromDnp={true}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPage={limit}
              setPerPage={(entriesPerPage) => setLimit(entriesPerPage)}
            />
          )}
        </Box>
      )}
    </>
  );
}
