import React, { useEffect, useState } from 'react';
import {
  FormControlLabel,
  Switch,
  Button,
  Stack,
  Box,
  Paper,
} from '@mui/material';
import {
  getCurrentAdminId,
  getCurrentAdminPrimaryRole,
  splitByUnderscoresAndCapitalize,
} from '../utils/common';
import axios from 'axios';
import { base_url } from './Mode';
import { allBucketSearchSettingColumns } from '../utils/bucket-search-setting-columns';
import ErrorNotifier from './ToastNotifications/ErrorNotifier';
import { defaultBucketSearchAdminColumns } from '../utils/default-bucket-search-admin-columns';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SuccessNotifier from './ToastNotifications/SuccessNotifier';

const BucketSearchSettings = () => {
  const [columnSettings, setColumnSettings] = useState(
    allBucketSearchSettingColumns
  );
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const role = getCurrentAdminPrimaryRole();
  const defaultSettingsOfTheRole = defaultBucketSearchAdminColumns[role] ?? {};

  useEffect(() => {
    axios
      .get(`${base_url()}/api/admins/${getCurrentAdminId()}/settings`)
      .then((res) => {
        const parsedData = JSON.parse(
          res?.data ?? defaultBucketSearchAdminColumns
        );
        // parsedData.last_interaction = false;
        // parsedData.last_interaction_on = false;
        // parsedData.last_interaction_by = false;
        setColumnSettings(getMergedContent(parsedData));
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          "An error occurred while fetching the user's saved settings"
        );
      });

    return () => {};
  }, []);

  const handleColumnToggle = (e, col) => {
    setColumnSettings((prevSettings) => ({
      ...prevSettings,
      [col]: e.target.checked,
    }));
  };

  const getMergedContent = (storedData) => {
    if (Object.keys(storedData).length === 0) {
      return {
        ...columnSettings,
        ...defaultSettingsOfTheRole,
        ...storedData,
      };
    }

    return storedData;
  };

  const handleSave = () => {
    const settingsData = JSON.stringify(columnSettings);

    axios
      .patch(`${base_url()}/api/admins/${getCurrentAdminId()}/settings`, {
        settings: settingsData,
      })
      .then((res) => {
        const parsedData = JSON.parse(res.data ?? '{}');
        setColumnSettings(getMergedContent(parsedData));
        setIsSuccess(true);
        setSuccessMessage('Settings successfully updated');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ??
            'An internal error occured while updating the admin setting'
        );
      });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedColumns = Array.from(Object.keys(columnSettings));
    const [removed] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, removed);

    const reorderedSettings = {};
    reorderedColumns.forEach((col) => {
      reorderedSettings[col] = columnSettings[col];
    });

    setColumnSettings(reorderedSettings);
  };

  return (
    <Stack spacing={2}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable
          droppableId='columnSettings'
          direction='vertical'
        >
          {(provided) => (
            <Stack
              sx={{
                height: '35vh',
                overflow: 'auto',
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
              spacing={1}
            >
              {Object.keys(columnSettings).map((col, index) => (
                <Draggable
                  key={col}
                  draggableId={col}
                  index={index}
                >
                  {(provided) => (
                    <Paper
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      sx={{
                        padding: '0.2rem',
                        width: '50%',
                      }}
                      variant='elevation'
                      elevation={2}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={columnSettings[col]}
                            onChange={(e) => handleColumnToggle(e, col)}
                          />
                        }
                        label={splitByUnderscoresAndCapitalize(col)}
                        sx={{
                          width: 'fit-content',
                        }}
                      />
                    </Paper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      <Stack direction='row-reverse'>
        <Button
          variant='contained'
          onClick={handleSave}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default BucketSearchSettings;
