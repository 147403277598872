import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import { GoogleLogin } from 'react-google-login';
import { useAuthStateValue } from '../components/auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import Mode from '../components/Mode';
import Cookies from 'universal-cookie';
import constants from '../utils/constants';
import { logOut } from '../components/SpeechRecognition/utils';
import { getCurrentAdminId, getCurrentAdminRole } from '../utils/common';

function Login() {
  const [user, setUser, isUserLoggedIn] = useAuthStateValue();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [reasonMessage, setReasonMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const userId = window.location.href?.split('?')[1];

  useEffect(() => {
    if (userId) {
      logOut(parseInt(userId), setIsLoading);
      navigate('/');
    }
    setReasonMessage(undefined);
  }, []);

  function responseSuccessGoogle(response) {
    axios
      .post(`${Mode.base_url()}/api/auth/login`, {
        tokenId: response.tokenId,
      })
      .then((res) => {
        if (res.data.data) {
          const admin = res.data.data.existingLead;
          admin.roles = (
            admin.roles ?? [{ name: constants.INDEPENDENT_CONTRIBUTOR }]
          ).map((role) => role.name);

          admin.subordinate_admin_ids =
            (res.data.data.subordinateAdminIds ?? []).length === 0
              ? [admin.id]
              : res.data.data.subordinateAdminIds;

          delete admin.created_at;
          delete admin.updated_at;

          setUser(res.data.data && res.data.data.tokenId);
          cookies.set('x-auth-token', res.data.data.tokenId, { path: '/' });
          localStorage.setItem('token', JSON.stringify(res.data.data.tokenId));
          localStorage.setItem('admin', JSON.stringify(admin));
          const isTraineeOrIC = [
            constants.INDEPENDENT_CONTRIBUTOR,
            constants.TRAINEE,
          ].includes(getCurrentAdminRole()[0]);

          if (isTraineeOrIC) {
            navigate('/leads/priority-list');
          } else {
            navigate('/dashboard/leaderboard');
          }
        } else {
          alert('Request failed. Unexpected response was received.');
          navigate('/');
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          // Response was received successfully and the data had an error message string
          setErrorMessage(err.response.data.message);
          if (err?.response?.data?.reason) {
            setReasonMessage(err?.response?.data?.reason);
          }
        } else if (err.message) {
          // The network call was unsuccessful (eg: CORS) and err.message was set
          setErrorMessage(err.message);
          console.log(JSON.parse(JSON.stringify(err)));
        }
      });
  }

  function responseFailureGoogle(response) {
    console.log(response);
  }

  return (
    <Box
      textAlign='center'
      margin={10}
    >
      <Typography
        variant={'h4'}
        m={2}
      >
        HeyCoach Sales portal
      </Typography>
      <Typography
        variant={'h6'}
        color={'red'}
      >
        {errorMessage}
      </Typography>
      {reasonMessage && (
        <Typography
          variant={'h6'}
          color={'red'}
        >
          Reason : {reasonMessage}
        </Typography>
      )}
      <br />
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText='Sign in with Google'
        onSuccess={responseSuccessGoogle}
        onFailure={responseFailureGoogle}
        style={{ width: '400px' }}
        disabled={isLoading}
      />
      <br />
      {isLoading && (
        <Typography
          variant={'h6'}
          m={2}
          sx={{ position: 'absolute', bottom: '25px', right: '50%' }}
          color={'#c1c1c1'}
          fontSize={'8px'}
        >
          Logout user.
        </Typography>
      )}
    </Box>
  );
}

export default Login;
