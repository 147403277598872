import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import webSocketAuth from './middlewares/socket';
import socketServices from '.';
import { getCurrentAdminId } from '../utils/common';
import constant from '../utils/constants';

const SocketContext = createContext();

const SocketProvider = ({ children, user }) => {
  const [socket, setSocket] = useState(null);
  const [triggerTimeout, setTriggerTimeout] = useState(false);
  const [interval2, setInterval2] = useState(null);

  useEffect(() => {
    try {
      if (user && constant.IC_ID_FOR_ONE_TAB.includes(getCurrentAdminId())) {
        const newSocket = webSocketAuth(io, user);
        setSocket(newSocket);
        if (newSocket) {
          socketServices(newSocket);
        }
      }
    } catch (e) {
      console.log('error from socket context', e);
      setSocket(null);
      cleanupEventListeners();
    }

    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
        cleanupEventListeners();
      }
    };
  }, [user]);
  const currentTime = new Date();
  // This code is for admin activity tracking it will be needed in future.
  // Before deleting need to approval to delete it.
  // let mouseEvent = {
  //   type: 'hover',
  //   timeStamp: currentTime.toISOString(),
  //   id: getCurrentAdminId(),
  // };

  // const debounce = (func, delay, mouseEvent, callback) => {
  //   let count = 0;
  //   let timer;
  //   let timer2;
  //   return function () {
  //     setTriggerTimeout(false);
  //     const context = this;
  //     const args = arguments;
  //     clearTimeout(timer2);

  //     if (count === 0) {
  //       timer = setInterval(() => {
  //         //triggering callback
  //         func.apply(context, args);
  //         setTriggerTimeout(true);
  //       }, 3000);
  //       count++;
  //     }
  //     timer2 = setTimeout(() => {
  //       //clearing out interval
  //       count = 0;
  //       clearInterval(timer);
  //     }, 1000);
  //   };
  // };

  // const sendPing = () => {
  //   mouseEvent.timeStamp = new Date().toISOString();
  //   console.log('pinging', mouseEvent);
  //   socket.emit('ping', mouseEvent);
  // };

  // const debouncedSendPing = debounce(
  //   sendPing,
  //   3000,
  //   mouseEvent,
  //   (trigger, timer2) => {
  //     clearInterval(interval2);
  //     timer2 = setInterval(() => {
  //       sendPing();
  //     }, 3000);
  //   }
  // );

  // const handleMouseMove = () => {
  //   debouncedSendPing();
  // };

  // const handleKeyPress = () => {
  //   debouncedSendPing();
  // };

  const handleConnect = () => {
    // console.log(socket);
    // document.addEventListener('mousemove', handleMouseMove);
    // document.addEventListener('keydown', handleKeyPress);
  };

  const handleDisconnect = () => {
    cleanupEventListeners();
  };

  const handleConnectError = (err) => {
    console.log('connect_error', err.message);
  };

  useEffect(() => {
    if (socket) {
      socket.on('connect', handleConnect);
      socket.on('connect_error', handleConnectError);
      socket.on('disconnect', handleDisconnect);
    }

    return () => {
      // Cleanup event listeners when component unmounts
      cleanupEventListeners();

      //Remove event listeners and reset retry counter
      if (socket) {
        socket.off('connect', handleConnect);
        socket.off('connect_error', handleConnectError);
        socket.off('disconnect', handleDisconnect);
      }
    };
  }, [socket]);

  const cleanupEventListeners = () => {
    // document.removeEventListener('mousemove', handleMouseMove);
    // document.removeEventListener('keydown', handleKeyPress);
  };

  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  };
  return (
    <SocketContext.Provider value={[socket, disconnectSocket]}>
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = () => {
  const socket = useContext(SocketContext);
  return socket;
};

export { SocketProvider, useSocket };
