import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCurrentLead } from '../CurrentLeadContext';
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import { addRecencyText } from '../../utils/AddRecencyText';
import {
  capitalize,
  getCurrentAdminId,
  getDurationText,
  getFormattedTimestamp,
  getTimeZoneFormattedTimestamp,
  splitByUnderscoresAndCapitalize,
} from '../../utils/common';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DraftsIcon from '@mui/icons-material/Drafts';
import DeleteIcon from '@mui/icons-material/Delete';
import PhoneCallbackRoundedIcon from '@mui/icons-material/PhoneCallbackRounded';
import PhoneMissedRoundedIcon from '@mui/icons-material/PhoneMissedRounded';
import { ConfirmInteractionDeletionModal } from './ConfirmInteractionDeletionModal';
import { base_url } from '../Mode';
import { getTimePeriod } from '../Sidebar';
import constants from '../../utils/constants';
import moment from 'moment/moment';
import CustomLoader from '../../pages/customLoader';

export const InteractionsList = ({
  adminMap,
  setSelectedEligibilityForm,
  setSelectedEfConversionFlow,
  setErrorText,
  setHasError,
  showActivity,
  callLog,
}) => {
  useEffect(() => {
    showActivity ? setSwitchTab(1) : setSwitchTab(0);
  }, [showActivity]);

  useEffect(() => {}, [callLog]);

  const [interactionId, setInteractionId] = useState(-1);
  const [switchTab, setSwitchTab] = useState(null);
  const [
    openDeleteInteractionConfirmation,
    setOpenDeleteInteractionConfirmation,
  ] = useState(false);
  const {
    loading,
    currentLead,
    getCurrentLeadInteractionsAndUpdateContext,
    currentLeadInteractions,
    currentLeadActivities,
    currentLeadStatusActivities,
    currentLeadSignups,
    currentLeadEligibilityForms,
  } = useCurrentLead();

  const handleDelete = () => {
    const url = `${base_url()}/api/interactions/${interactionId}`;

    axios
      .delete(url)
      .then((res) => {
        getCurrentLeadInteractionsAndUpdateContext(currentLead.id);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorText(
          err.response?.data?.message || "Couldn't delete the interaction."
        );
      })
      .finally(() => {
        setOpenDeleteInteractionConfirmation(false);
      });
  };

  const isDeletable = (interaction) => {
    if (interaction.updated_by !== getCurrentAdminId()) {
      return false;
    }

    const now = getTimeZoneFormattedTimestamp(new Date(), 0, 0, false);
    const interactionCreatedAt = getTimeZoneFormattedTimestamp(
      new Date(interaction.created_at),
      0,
      0,
      false
    );
    const timeElapsed = getTimePeriod(now, interactionCreatedAt, true) * 60; // in minutes

    if (timeElapsed > 30) {
      return false;
    }

    const len = currentLeadActivities.length;
    let lastActivityCreatedAt = null;

    for (let i = len - 1; i >= 0; --i) {
      const { name, created_at } = currentLeadActivities[i];
      if (name !== constants.NEW_INTERACTION) {
        lastActivityCreatedAt = getTimeZoneFormattedTimestamp(
          new Date(created_at),
          0,
          0,
          false
        );
        break;
      }
    }

    return (
      !lastActivityCreatedAt || lastActivityCreatedAt < interactionCreatedAt
    );
  };

  const interactions = currentLeadInteractions.map(function (interaction) {
    const noteslist = interaction.notes.split('\n');
    var notesHTML = [];
    noteslist.forEach(function (noteText) {
      const notePrefix = noteText.split(': ');
      var noteLine = [];
      // Highlight only if it is used as a title (title would not be more than 30-35 chars long).
      if (notePrefix.length > 1 && notePrefix[0].length <= 35) {
        noteLine.push(<font color='brown'>{notePrefix[0]}: </font>);
        notePrefix.shift();
        noteLine.push(notePrefix.join(': '));
      } else {
        noteLine.push(notePrefix.join(': '));
      }
      notesHTML.push(
        <>
          <span>{noteLine}</span>
          <br />
        </>
      );
    });

    const interactionFiles = [];
    (interaction.interaction_files ?? []).forEach((file) => {
      interactionFiles.push(
        <Chip
          label={file.file_name}
          onClick={(e) => {
            window.open(file.url, '_target', 'noopener');
          }}
          size='small'
        />
      );
    });

    if (interactionFiles.length > 0) {
      notesHTML.push(
        <Stack
          direction='row'
          useFlexGap
          flexWrap='wrap'
          gap={1}
          mt='1px'
        >
          {interactionFiles}
        </Stack>
      );
    }

    const timestamp = new Date(Date.parse(interaction.created_at));
    const { fname = '', lname, access_type } = adminMap[interaction.updated_by];

    return {
      timestamp: timestamp,
      data: (
        <Box
          onMouseEnter={() => {
            if (isDeletable(interaction)) {
              setInteractionId(interaction.id);
            }
          }}
          onMouseLeave={() => {
            setInteractionId(-1);
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              component='div'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                float={'right'}
                mt={'10px'}
                display={'inline-block'}
                style={{ marginRight: 10 }}
              >
                <strong>
                  {access_type === 'disabled'
                    ? 'System user'
                    : [fname, lname || ''].join(' ')}
                </strong>
              </Typography>
            </Box>
            <Box component='div'>
              <Typography
                float={'right'}
                mt={'10px'}
                display={'inline-block'}
                pr={'20px'}
                sx={{
                  float: 'right',
                  fontSize: '12px',
                }}
                color={'#9e9e9e'}
              >
                {addRecencyText(timestamp)} {getFormattedTimestamp(timestamp)}
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: '#ebf8ff',
              padding: '12px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
            m={'0px 10px 10px 0px'}
            borderRadius={'5px'}
            key={interaction.id}
          >
            <Typography mt={1}>{notesHTML}</Typography>
            {interactionId === interaction.id && (
              <DeleteIcon
                onClick={(e) => setOpenDeleteInteractionConfirmation(true)}
                fontSize='small'
                sx={{
                  cursor: 'pointer',
                }}
                color='error'
              />
            )}
          </Box>
          {openDeleteInteractionConfirmation && (
            <ConfirmInteractionDeletionModal
              {...{
                handleDelete,
                openDeleteInteractionConfirmation,
                setOpenDeleteInteractionConfirmation,
              }}
            />
          )}
        </Box>
      ),
    };
  });

  const Activity = (props) => {
    return (
      <Grid
        container
        textAlign={'left'}
        pl={'0px'}
        my={'5px'}
        {...(props.onClick && {
          onClick: props.onClick,
        })}
        {...(props.sx && { sx: props.sx })}
      >
        <Grid
          item
          xs={6}
        >
          <Typography
            fontWeight={'normal'}
            width={'fit-content'}
            display={'inline-block'}
            px={'10px'}
            borderRadius={'10px'}
            fontSize={'smaller'}
            color={'#757575'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              backgroundColor: props.backgroundColor || 'unset',
              padding: props.padding || '0',
            }}
          >
            {props.children}
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          textAlgin={'left'}
        >
          <Typography
            fontSize={'12px'}
            display={'inline-block'}
            pr={'20px'}
            style={{ float: 'right' }}
            color={'#9e9e9e'}
          >
            {props.timestamp}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const signups = currentLeadSignups.map(function (signup) {
    let sourceDetails = '';
    if (
      signup.utm_source ||
      signup.utm_medium ||
      signup.utm_campaign ||
      signup.utm_term ||
      signup.utm_content
    ) {
      sourceDetails = (
        <>
          &nbsp;through {signup.utm_source} {signup.utm_medium}{' '}
          {signup.utm_campaign} {signup.utm_term} {signup.utm_content}
        </>
      );
    }
    const timestamp = new Date(Date.parse(signup.created_at));
    return {
      timestamp: timestamp,
      data: (
        <Activity
          backgroundColor={'#F0E0F0'}
          padding={'6px 10px'}
          timestamp={getFormattedTimestamp(timestamp)}
        >
          Signed up {signup.program ? ' for ' + signup.program : ''}
          {sourceDetails}
        </Activity>
      ),
    };
  });

  const activities = [];
  const hiddenActivities = [
    'lead_status_update',
    'interaction_update',
    'auto_assigned_leads',
    'login',
    'logout',
    'page_access',
  ];

  const switchTabAndActivityCheck = (switchTab, activityName) => {
    if (
      (switchTab === 2 && activityName === 'lead_details_update') ||
      (switchTab === 3 && activityName === 'snooze_update') ||
      (switchTab === 3 && activityName === 'lead_created') ||
      (switchTab !== 0 && activityName === 'did_not_pick') ||
      (switchTab !== 0 && activityName === 'requested_callback')
    ) {
      return false; // Don't push
    }
    return true;
  };

  currentLeadActivities.forEach(function (activity) {
    if (hiddenActivities.find((act) => act === activity.name)) {
      return;
    }

    const efExpiryFormatted = (activity) => {
      const date = new Date(activity.metadata);
      return getFormattedTimestamp(new Date(date));
    };

    const timestamp = new Date(Date.parse(activity.created_at));
    let activityText = undefined;
    let activityIcon = undefined;
    let doer = 'unknown user';

    if (activity?.action_by) {
      const { fname, lname } = adminMap[activity.action_by];
      doer =
        adminMap[activity.action_by].access_type === 'disabled'
          ? 'System user'
          : [fname, lname || ''].join(' ');
    }

    let date;
    switch (activity.name) {
      case 'assignee_update':
        if (activity.metadata == null) {
          activityText = doer + ' removed the assignee';
        } else if (activity.action_by == activity.metadata) {
          activityText = doer + ' assigned to self';
        } else {
          const isObj = JSON.parse(activity.metadata);
          let adminInfo;
          let flagFor10minConnect = false;
          let sixDNPFlag = false;

          if (isObj && Object.keys(isObj).length) {
            if (isObj.id) {
              adminInfo = adminMap[isObj.id];
            } else {
              adminInfo = adminMap[isObj.assignee];
              flagFor10minConnect = isObj.assigned_by === '10MinutConnect';
              sixDNPFlag = isObj?.reason === '6th DNP';
            }
          } else {
            adminInfo = adminMap[activity.metadata];
          }

          if (adminInfo && adminInfo.access_type) {
            const { access_type, fname, lname } = adminInfo;
            activityText =
              doer +
              ' assigned this lead to ' +
              (access_type === 'disabled'
                ? 'System user'
                : [fname, lname || ''].join(' ')) +
              (flagFor10minConnect
                ? ' through 10 minutes connect.'
                : sixDNPFlag
                ? `for ${isObj?.reason}`
                : isObj?.message
                ? `for ${isObj?.message}`
                : '');
          } else {
            activityText = 'Assignee is not in system';
          }
        }
        activityIcon = <EditIcon fontSize={'small'} />;
        break;
      case 'snooze_update':
        if (activity.metadata == null || activity.metadata === '') {
          activityText = doer + ' removed snooze';
        } else {
          // added the check if the metadata is object then extract date from it otherwise normal flow. This is done beacuse we are adding the object in metadata.
          try {
            const parsedData = JSON.parse(activity.metadata);
            date = Object.keys(parsedData).includes('date')
              ? parsedData.date
              : '';
          } catch (error) {
            date = activity.metadata;
          }
          activityText =
            doer +
            ' has snoozed this lead to ' +
            getFormattedTimestamp(new Date(date));
        }
        activityIcon = <AccessTimeIcon fontSize={'small'} />;
        break;
      case 'blacklist_update':
        if (activity.metadata === 'added') {
          activityText = doer + ' has blacklisted this lead';
        } else if (activity.metadata === 'removed') {
          activityText = doer + ' has removed this lead from blacklist';
        }
        activityIcon = <EditIcon fontSize={'small'} />;
        break;
      case 'product_update':
        if (activity.metadata === null || activity.metadata === '') {
          activityText = doer + ' removed product';
        } else {
          activityText = capitalize(
            doer + ' updated the product to ' + activity.metadata
          );
        }
        activityIcon = <EditIcon fontSize={'small'} />;
        break;
      case 'lead_details_update':
        const parsedFields = JSON.parse(activity.metadata ?? {});
        const updatedFields = Object.keys(parsedFields)
          .filter((f) => f !== 'old_data')
          .join(', ');

        if (updatedFields === '') return;
        activityText =
          doer + ' has updated the ' + updatedFields + ' of this lead';
        activityIcon = <EditIcon fontSize={'small'} />;
        break;
      case 'template_message_sent':
        activityText = doer + ' has sent a message to this lead';
        activityIcon = <DraftsIcon fontSize={'small'} />;
        break;
      case 'lead_source_type':
        activityText =
          splitByUnderscoresAndCapitalize(activity.name) +
          ' has been updated from ' +
          activity.metadata;
        break;
      case 'did_not_pick':
        let frequency = '';
        let sanitizedActivityName = splitByUnderscoresAndCapitalize(
          activity.name
        );
        sanitizedActivityName =
          sanitizedActivityName.charAt(0).toLowerCase() +
          sanitizedActivityName.slice(1);

        if (activity.metadata === 'frequency:1') {
          frequency = 'once';
        } else {
          frequency = 'twice';
        }
        activityText =
          doer + ' dialled ' + frequency + ' but lead ' + sanitizedActivityName;
        break;

      case 'requested_callback':
        try {
          const parsedData = JSON.parse(activity.metadata);
          date = Object.keys(parsedData).includes('date')
            ? parsedData.date
            : '';
        } catch (error) {
          date = activity.metadata;
        }
        activityText =
          'Lead requested callback from ' +
          doer +
          ' at ' +
          getFormattedTimestamp(new Date(date));
        break;
      case 'lead_drop_reasons_modified':
        activityText =
          splitByUnderscoresAndCapitalize(activity.name) +
          ' by ' +
          doer +
          ' ' +
          formatString(activity.metadata);
        activityIcon = <EditIcon fontSize={'small'} />;
        break;
      case 'lead_drop_action':
        activityText =
          splitByUnderscoresAndCapitalize(activity.name) +
          ' ' +
          activity.metadata +
          ' by ' +
          doer;
        activityIcon = <EditIcon fontSize={'small'} />;
        break;
      case 'ef_not_sent':
        activityText = `${doer} chose to not sent EF form reason: ${activity.metadata}`;
        activityIcon = <EditIcon fontSize={'small'} />;
        break;
      case 'dnp_not_marked':
        activityText = `${doer} chose to not mark double DNP reason: ${activity.metadata}`;
        activityIcon = <EditIcon fontSize={'small'} />;
        break;
      case 'ef_expiry_update':
        activityText = `${doer} updated EF form expiry to ${efExpiryFormatted(
          activity
        )}.`;
        activityIcon = <EditIcon fontSize={'small'} />;
        break;
      default:
        activityText =
          splitByUnderscoresAndCapitalize(activity.name) + ' by ' + doer;
    }
    //  rendering related activities on tab change
    const shouldPushActivity = (switchTab, activityName) => {
      return switchTabAndActivityCheck(switchTab, activityName);
    };

    function formatString(inputString) {
      try {
        let parts = JSON.parse(inputString);

        const proposedPrimaryReason = parts?.previousDropReason?.primary;
        const modifiedPrimaryReason = parts.changedDropReason.primary;

        const proposedSecondaryReason =
          parts?.previousDropReason?.secondary[0].value;
        const modifiedSecondaryReason =
          parts.changedDropReason?.secondary[0].value;

        const primaryModified = `changed Primary Drop reason from ${proposedPrimaryReason} to ${modifiedPrimaryReason}`;
        const secondaryModified = `changed Secondary Drop reason from ${proposedSecondaryReason} to ${modifiedSecondaryReason}`;

        if (
          proposedPrimaryReason !== modifiedPrimaryReason &&
          proposedSecondaryReason !== modifiedSecondaryReason
        ) {
          return `${primaryModified} & ${secondaryModified}`;
        }

        if (proposedSecondaryReason !== modifiedSecondaryReason) {
          return secondaryModified;
        }

        return ``;
      } catch (error) {
        console.log(error);
        throw error;
      }
    }

    // adding icons
    switch (activity.name) {
      case 'lead_created':
        activityIcon = <AddIcon fontSize={'small'} />;
        break;
      case 'archived':
        activityIcon = <ArchiveIcon fontSize={'small'} />;
        break;
      case 're-opened':
        activityIcon = <UnarchiveIcon fontSize={'small'} />;
        break;
      case 'did_not_pick':
        activityIcon = <PhoneMissedRoundedIcon fontSize={'small'} />;
        break;
      case 'requested_callback':
        activityIcon = <PhoneCallbackRoundedIcon fontSize={'small'} />;
        break;
      default:
    }

    if (
      (activity.name === 'did_not_pick' ||
        activity.name === 'requested_callback' ||
        activity.name === constants.EF_NOT_SENT ||
        activity.name === constants.DNP_NOT_MARKED) &&
      showActivity
    ) {
      activities.push({
        timestamp: timestamp,
        data: (
          <Activity
            timestamp={getFormattedTimestamp(timestamp)}
            padding={
              activity.name === constants.EF_NOT_SENT ||
              activity.name === constants.DNP_NOT_MARKED
                ? '0px'
                : activity.name === 'did_not_pick'
                ? '6px 20px'
                : '6px 10px'
            }
            backgroundColor={
              activity.name === constants.EF_NOT_SENT ||
              activity.name === constants.DNP_NOT_MARKED
                ? 'transparent'
                : activity.name === 'did_not_pick'
                ? '#FFA6A6'
                : '#7BD0FF'
            }
          >
            {activityIcon}
            {activityText}
          </Activity>
        ),
      });
    }

    if (shouldPushActivity(switchTab, activity.name) && !showActivity) {
      activities.push({
        timestamp: timestamp,
        data: (
          <Activity
            timestamp={getFormattedTimestamp(timestamp)}
            backgroundColor={
              activity.name === 'did_not_pick'
                ? '#FFA6A6'
                : activity.name === 'requested_callback'
                ? '#7BD0FF'
                : ''
            }
            padding={
              activity.name === 'did_not_pick' ||
              activity.name === 'requested_callback'
                ? '6px 10px'
                : ''
            }
          >
            {activityIcon}
            {activityText}
          </Activity>
        ),
      });
    }
  });

  const statusActivities = () => {
    let onlyCPEorAllcurrentLeadStatusActivities = currentLeadStatusActivities;

    if (showActivity) {
      onlyCPEorAllcurrentLeadStatusActivities =
        currentLeadStatusActivities.filter(
          (e) => e.status === constants.COMPLETE_PROGRAM_EXPLAINED
        );
    }

    return onlyCPEorAllcurrentLeadStatusActivities.map((activity) => {
      const timestamp = new Date(Date.parse(activity.created_at));
      const updatedByFromAdminMap = adminMap[activity.updated_by];
      if (!updatedByFromAdminMap) {
        return null;
      }
      const { fname, lname } = updatedByFromAdminMap;
      const doer =
        adminMap[activity.updated_by].access_type === 'disabled'
          ? 'System user'
          : [fname, lname || ''].join(' ');
      const activityIcon = <PublishedWithChangesIcon fontSize={'small'} />;

      const isLeadDropped = activity.drop_reasons.length > 0;
      const primaryReasons = isLeadDropped
        ? activity.drop_reasons[0].primary_reason
        : null;
      const activityText = isLeadDropped
        ? `${doer} changed the status to dropped. Reason: '${primaryReasons}'`
        : `${doer} changed the status to ${splitByUnderscoresAndCapitalize(
            activity.status
          )}`;

      return {
        timestamp: timestamp,
        data: (
          <Activity timestamp={getFormattedTimestamp(timestamp)}>
            {activityIcon}
            {activityText}
          </Activity>
        ),
      };
    });
  };

  const callLogs = callLog?.map((reportEntry) => {
    let { calledOn, type, duration, admin_id, fname, lname } = reportEntry;
    const timestamp = new Date(calledOn);
    // timestamp.setHours(timestamp.getHours());
    // timestamp.setMinutes(timestamp.getMinutes() - 30);

    const doer =
      adminMap[admin_id].access_type === 'disabled'
        ? 'System user'
        : [fname, lname || ''].join(' ');

    type = (type ?? '').toLowerCase();
    let activityText = `Call log by ${doer}: `;
    let activityIcon = <PhoneForwardedIcon fontSize={'small'} />;
    if (type === 'rejected') {
      activityText += `call rejected.`;
    } else if (type === 'missed') {
      activityText += `missed a call from lead.`;
    } else {
      activityText += `${type} call for ${getDurationText(duration)}.`;
    }

    return {
      timestamp: timestamp,
      data: (
        <Activity
          backgroundColor={'#f0f4c3'}
          padding={'6px 10px'}
          timestamp={getFormattedTimestamp(timestamp)}
        >
          {activityIcon} {activityText}
        </Activity>
      ),
    };
  });

  const cpeDetails = currentLead?.cpeDetail?.map((entry) => {
    const { updated_by, fname, lname, metadata, created_at } = entry;

    const timestamp = new Date(created_at);

    const doer =
      adminMap[updated_by].access_type === 'disabled'
        ? 'System user'
        : [fname, lname || ''].join(' ');

    let activityText = `${doer}:has marked ${splitByUnderscoresAndCapitalize(
      constants.COMPLETE_PROGRAM_EXPLAINED
    )} `;

    if (metadata?.total_call_duration) {
      activityText += `with call duration ${metadata?.total_call_duration} min`;
    }

    let activityIcon = <PublishedWithChangesIcon fontSize={'small'} />;
    return {
      timestamp: timestamp,
      data: (
        <Activity timestamp={getFormattedTimestamp(timestamp)}>
          {activityIcon} {activityText}
        </Activity>
      ),
    };
  });

  const eligibilityForms = currentLeadEligibilityForms.map((form) => {
    const { lead, created_at, response, ef_conversation_flow } = form;
    const fullname = [lead.fname, lead.lname ?? ''].join(' ');
    const text1 = `View eligibility form response submitted by ${fullname}`;
    const text2 = 'Eligibility form created. Awaiting response';

    const jsonParsedResponse = JSON.parse(response);
    const timestamp = new Date(Date.parse(created_at));

    let efConversationFlowParsed = '';
    if (ef_conversation_flow?.status === 'done') {
      efConversationFlowParsed = JSON.parse(
        ef_conversation_flow?.response ?? '{}'
      );
    }

    return {
      timestamp: timestamp,
      data: (
        <Activity
          backgroundColor={response ? '#c8e6c9' : '#ffeeda'}
          padding={'6px 10px'}
          timestamp={getFormattedTimestamp(timestamp)}
          onClick={(e) => {
            if (response) {
              setSelectedEligibilityForm(jsonParsedResponse);
            }
            if (efConversationFlowParsed) {
              setSelectedEfConversionFlow([efConversationFlowParsed]);
            }
          }}
          sx={{
            cursor: response && 'pointer',
          }}
        >
          {response ? text1 : text2}
        </Activity>
      ),
    };
  });

  const details = [];
  switch (showActivity) {
    case false:
      //All Tab
      details.push(...signups);
      details.push(...interactions);
      details.push(...activities);
      details.push(...statusActivities());
      details.push(...eligibilityForms);
      if (callLogs && callLogs.length > 0) details.push(...callLogs);
      if (cpeDetails && cpeDetails.length > 0) details.push(...cpeDetails);
      break;
    case true:
      // External Tab
      details.push(...signups);
      details.push(...interactions);
      if (activities && activities.length > 0) details.push(...activities);
      details.push(...eligibilityForms);
      if (statusActivities() && statusActivities().length > 0)
        details.push(...statusActivities());
      if (cpeDetails && cpeDetails.length > 0) details.push(...cpeDetails);
      break;
    default:
      details.push(...signups);
      details.push(...interactions);
      details.push(...activities);
      details.push(...statusActivities());
      details.push(...eligibilityForms);
      break;
  }

  details.sort(function (a, b) {
    const keyA = new Date(a.timestamp),
      keyB = new Date(b.timestamp);
    // Compare the 2 dates
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });

  return (
    <>
      {(loading.Lead ||
        loading.Interactions ||
        loading.Activities ||
        loading.statusActivity ||
        loading.Signups ||
        loading.ElgibilityForm) && <CustomLoader height={90} />}
      {details.length > 0 && details.map((a) => a.data)}
      {details.length === 0 && (
        <div
          className='wrapper'
          style={{
            height: `90vh`,
          }}
        >
          <Typography>No Relevant Data</Typography>
        </div>
      )}
    </>
  );
};
