import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { base_url } from '../Mode';
import Zoom from '@mui/material/Zoom';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import {
  getStatusLabel,
  currentAdminIsManagerOrAdminOrMarketing,
  getCurrentAdminId,
  currentAdminIsTrainee,
  currentAdminIsSeniorManager,
  showEFSBanner,
  currentAdminIsMarketing,
  currentAdminIsManager,
  currentAdminIsSenior_marketing_manager,
} from '../../utils/common';
import { useCurrentLead } from '../CurrentLeadContext';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Stack,
  Tooltip,
  Box,
} from '@mui/material';
import constants from '../../utils/constants';
import ScreeningCallMetaDataDialog from './ScreeningCallMetaDataDialog';
import ScreeningCallMetaDataSchedule from './ScreeningCallMetaDataSchedule';
import AdminsAutocompleteDropdown from '../admins/AdminAutoComplete';
import DropReasonModal from './DropReasonModal';
import OfferLetterModal from '../OfferLetterModal';
import { CreateAndUpdateOfferLetter } from './CreateAndUpdateOfferLetter';
import { keyframes } from '@emotion/react';
import CallDurationMetadataDialog from './CallDurationMetadataDialog';
import { currentAdminIsAdmin } from '../../utils/common';
import { EligibilityForm } from './EligibilityForm';
import ScheduleMeetingPopUp from '../schedulingAutomation/scheduleMeetingPopUp';
import SignupsModal from './SiignUpModal/SignupModal';
const blinkAnimation = keyframes`
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
`;

const { TOKEN_AMOUNT_PAID, COMPLETE_AMOUNT_PAID, DROPPED, MANAGER_IDS } =
  constants;

function PartialViewLeadEditForm({
  salesClouserPaymentsData,
  setSalesClouserPaymentsData,
  setShowConfetties,
  allDropReasons,
  selectedReasons,
  setSelectedReasons,
  leadDropRequest,
  showEFSBannerStatus,
  showDnpBanner,
}) {
  const {
    currentLead,
    currentLeadStatusActivities,
    getCurrentLeadAndUpdateContext,
    getCurrentLeadStatusActivitiesAndUpdateContext,
    getCurrentLeadSignupsAndUpdateContext,
  } = useCurrentLead();
  const [editedFields, setEditedFields] = useState({});
  const [intermediateStatusValue, setIntermediateStatusValue] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [nextStatusList, setNextStatusList] = useState([]);
  const [openStatusConfirmationModal, setOpenStatusConfirmationModal] =
    useState(false);
  const [statusUpdateConfirmed, setStatusUpdateConfirmed] = useState(false);
  const [openDropReasonModal, setOpenDropReasonModal] = useState(false);

  const [openOfferLetterModal, setOpenOfferLetterModal] = useState(false);
  const [activeOLInfo, setActiveOLInfo] = useState({});

  const [openRecordingOffModal, setOpenRecordingOffModal] = useState(false);
  const [openScreeningScheduleModal, setOpenScreeningScheduleModal] =
    useState(false);
  const [leadStatusActivity, setLeadStatusActivity] = useState({});
  const [leadScheduledStatusActivity, setLeadScheduledStatusActivity] =
    useState({});
  const [metadata, setMetadata] = useState({});
  const [
    metadataForScreeningCallAttended,
    setMetadataForScreeningCallAttended,
  ] = useState({});
  const [
    metadataForScreeningCallSchedule,
    setMetadataForScreeningCallSchedule,
  ] = useState({});
  const [
    openCompleteProgramExplainedModal,
    setOpenCompleteProgramExplainedModal,
  ] = useState(false);
  const [openSignupModal, setOpenSignupModal] = useState(false);
  const [signupModalData, setSignupModalData] = useState({});
  const [showJunkModal, setShowJunkModal] = useState(false);

  const [openScheduleMeetingPopup, setOpenScheduleMeetingPopup] =
    useState(false);

  useEffect(() => {
    if (statusUpdateConfirmed) {
      setEditedFields({
        ...editedFields,
        ...intermediateStatusValue,
      });

      setIntermediateStatusValue({});
      setStatusUpdateConfirmed(false);
    }
    return () => {};
  }, [statusUpdateConfirmed]);

  useEffect(() => {
    getLeadActivitiesMetadata();
    getLeadScheduledActivitiesMetadata();
    return () => {};
  }, [currentLead]);

  useEffect(() => {
    if (Object.keys(editedFields).length === 0) {
      return;
    }

    const {
      status,
      assignee = '',
      dropReason = '',
      junkLead = '',
    } = editedFields;

    if (
      status === constants.DROPPED &&
      !junkLead &&
      currentLead.status === constants.CONNECT_ATTEMPTED
    ) {
      setShowJunkModal(true);
      return;
    }
    if (status === constants.ASSIGNED && assignee === '') {
      return;
    }

    submitHandler();
    return () => {};
  }, [editedFields]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' || currentAdminIsAdmin()) {
      setNextStatusList(currentLead.nextStatus);
    } else if (currentAdminIsSeniorManager()) {
      filterUnwantedStatusesInProd(
        currentLead.nextStatus,
        statusesToFilterForAnupOrOmar
      );
    } else {
      filterUnwantedStatusesInProd(currentLead.nextStatus, statusesToFilter);
    }
  }, [currentLead.nextStatus]);

  useEffect(() => {
    handleSignupEntry();
  }, [signupModalData]);

  const handleDroppedRequest = () => {
    if (currentLead.status === constants.CONNECT_ATTEMPTED) {
      setShowJunkModal(true);
    } else {
      submitHandler();
    }
  };

  const handleSignupEntry = () => {
    if (Object.keys(signupModalData).length > 0) {
      axios
        .post(
          `${base_url()}/api/users/${currentLead.id}/signups`,
          signupModalData
        )
        .then((res) => {
          setIsSuccess(true);
          setSuccessMessage('Signup entry created successfully...');
          updateLeadDetailState('status', constants.REACTIVATED);
          setSignupModalData({});
          getCurrentLeadSignupsAndUpdateContext(currentLead.id);
        })
        .catch((err) => {
          setHasError(true);
          setErrorMessage('Error while creating the signup entry');
          console.error('Error while fetching activity metadata:', err);
        });
    }
  };

  const handleOpenRecordingOffModal = () => {
    setOpenRecordingOffModal(true);
  };

  const getLeadActivitiesMetadata = async () => {
    await axios
      .get(
        `${base_url()}/api/leads/${
          currentLead.id
        }/last_screening_attended_status_activity`
      )
      .then((res) => {
        setLeadStatusActivity(res.data);
      })
      .catch((err) => {
        console.error('Error while fetching activity metadata:', err);
      });
  };

  const getLeadScheduledActivitiesMetadata = async () => {
    await axios
      .get(
        `${base_url()}/api/leads/${
          currentLead.id
        }/last_screening_scheduled_status_activity`
      )
      .then((res) => {
        setLeadScheduledStatusActivity(res.data);
      })
      .catch((err) => {
        console.error('Error while fetching activity metadata:', err);
      });
  };

  const handleOpenDurationModal = () => {
    setOpenCompleteProgramExplainedModal(true);
  };

  const handleDropReasonModalClose = (type) => {
    if (type === 'cancel') {
      const fields = editedFields;
      delete fields.status;
      setEditedFields(fields);
      setSelectedReasons({
        primary: '',
        secondary: {},
      });
    }
    setOpenDropReasonModal(false);

    if (type === 'save') {
      // Check if the admin is trainee then directly drop the lead
      if (currentAdminIsTrainee()) {
        // Call drop function for trainees
        submitHandler();
      } else {
        // Call Manager actions function for non-trainees
        dropRequestManagerAction(selectedReasons);
      }
    }
  };

  const dropRequestManagerAction = async (selectedReasons) => {
    const leadId = currentLead.id;
    const leadStatus = currentLead.status;
    if (leadStatus === DROPPED) {
      setHasError(true);
      setErrorMessage('lead is already in drop status.');
      return;
    }
    try {
      await axios.post(`${base_url()}/api/manager_actions/drop_request`, {
        selectedReasons,
        leadId,
        leadStatus,
      });
      leadDropRequest(leadId);
    } catch (err) {
      console.log(err);
      setHasError(true);
      setErrorMessage(
        err.response?.data?.error || "Couldn't make the drop request."
      );
    }
  };

  const statusesToFilter = [
    constants.ELIGIBILITY_FORM_SENT,
    constants.ELIGIBILITY_FORM_FILLED,
    constants.OFFER_LETTER_PAYMENT_LINK_SENT,
    constants.TOKEN_AMOUNT_PAID,
  ];

  const statusesToFilterForAnupOrOmar = [constants.ELIGIBILITY_FORM_SENT];

  // some status are no longer allowed on production mode because the system is set to update those statuses automatically on performing several other actions. Eg: when an eligibility form is sent, the status is automatically changed to eligibility_form_sent. We do not want the users to manually update the status without performing the action.
  const filterUnwantedStatusesInProd = (dataArray, statusesToFilter) => {
    const filterArrayByStatus = (dataArray, statusesToFilter) => {
      if (getCurrentAdminId() === 101) {
        statusesToFilter.push(constants.TOKEN_AMOUNT_PAID);
      }
      if (currentAdminIsSeniorManager()) {
        statusesToFilter.push(constants.REACTIVATED);
      }
      return dataArray.filter(
        (item) => !statusesToFilter.includes(item.status)
      );
    };

    const filteredResult = filterArrayByStatus(dataArray, statusesToFilter);
    setNextStatusList(filteredResult);
  };

  const updateLeadDetailState = (name, value) => {
    if (name === 'status') {
      const statuses = [
        constants.DROPPED,
        constants.TOKEN_AMOUNT_PAID,
        constants.COMPLETE_AMOUNT_PAID,
        constants.DROPPED_POST_SALES_REFUND_INITIATED,
        constants.DROPPED_POST_SALES_REFUND_DENIED,
        constants.PAYMENT_LINK_SENT,
      ];

      // get available duration
      let durationOfScreeningCall = leadStatusActivity?.metadata?.duration;

      // if current lead is having status from below defined list
      let currentStatusOfLead = [
        constants.SCREENING_CALL_ATTENDED,
        constants.OFFER_LETTER_PAYMENT_LINK_SENT,
        constants.TOKEN_AMOUNT_PAID,
      ].includes(currentLead.status);

      // if the value of updating status is DROP or CAP and current lead is having a status from above list
      if (
        name === 'status' &&
        (value === constants.DROPPED ||
          value === constants.COMPLETE_AMOUNT_PAID) &&
        currentStatusOfLead
      ) {
        // if duration is available
        if (!durationOfScreeningCall) {
          setHasError(true);
          setErrorMessage('Please add end time for screening call');
          return;
        }
      }

      if (statuses.includes(value)) {
        setIntermediateStatusValue({ [name]: value });
        setOpenStatusConfirmationModal(true);
        return;
      }
    }

    if (name === 'status' && value === constants.DROPPED) {
      setOpenDropReasonModal(true);
    }

    setEditedFields({
      ...editedFields,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    updateLeadDetailState(name, value);
  };

  const getFieldValue = (key) => {
    if (key in editedFields) {
      if ((editedFields[key] ?? '') === '') {
        setEditedFields((prev) => {
          delete prev[key];
          return prev;
        });
      } else {
        return editedFields[key];
      }
    }

    if (key !== 'dropReason') {
      return currentLead[key];
    }

    if (currentLeadStatusActivities.length === 0) {
      return '';
    }

    const { status } = currentLeadStatusActivities.at(-1);
    if (!Object.values(constants).includes(status)) {
      // this is a drop reason;
      return status;
    }
    return '';
  };

  const shouldAllowEligibilityFormUpdate = () => {
    return (
      currentLead.status === 'eligibility_form_sent' ||
      currentLead.status === 'complete_program_explained' ||
      currentLead.status === 'eligibility_form_filled'
    );
  };

  const shouldAllowScreeningCallUpdate = () => {
    return currentLead.status === constants.SCREENING_CALL_SCHEDULED;
  };

  const shouldAllowEndRecording = () => {
    const isScreeningCallStatus = [
      constants.SCREENING_CALL_ATTENDED,
      constants.OFFER_LETTER_PAYMENT_LINK_SENT,
      constants.TOKEN_AMOUNT_PAID,
    ].includes(currentLead.status);
    const isMetadataEmpty =
      Object.keys(leadStatusActivity?.metadata ?? {}).length === 0;
    const isDurationNull =
      isMetadataEmpty || leadStatusActivity?.metadata?.duration === null;
    return isScreeningCallStatus && (isMetadataEmpty || isDurationNull);
  };

  const handleStatusListChange = (status) => {
    if (status === constants.COMPLETE_PROGRAM_EXPLAINED) {
      handleOpenDurationModal();
    } else if (
      status === constants.COMPLETE_AMOUNT_PAID ||
      status === constants.TOKEN_AMOUNT_PAID
    ) {
      if (
        currentLead.assignee === getCurrentAdminId() ||
        currentAdminIsAdmin() ||
        (currentAdminIsSeniorManager() &&
          getCurrentAdminId() === constants.ANUP_ID)
      ) {
        updateLeadDetailState('status', status);
      } else {
        setHasError(true);
        setErrorMessage('Only Ic can update the status');
      }
    } else if (status === constants.SCREENING_CALL_SCHEDULED) {
      showScreeningPopup();
    } else if (status === constants.REACTIVATED) {
      // if (currentAdminIsManager() || currentAdminIsSeniorManager()) {
      //   setHasError(true);
      //   setErrorMessage("You don't have access to reactivate the lead.");
      // } else
      if (
        currentAdminIsMarketing() ||
        currentAdminIsSenior_marketing_manager()
      ) {
        setOpenSignupModal(true);
      } else {
        updateLeadDetailState('status', status);
      }
    } else {
      updateLeadDetailState('status', status);
    }
  };

  const disableStatusUpdateButtonForManagers = (status) => {
    const statusList = [
      constants.CONNECT_ATTEMPTED,
      constants.COMPLETE_PROGRAM_EXPLAINED,
      constants.ELIGIBILITY_FORM_SENT,
      constants.ELIGIBILITY_FORM_FILLED,
      constants.OFFER_LETTER_PAYMENT_LINK_SENT,
      constants.TOKEN_AMOUNT_PAID,
      constants.COMPLETE_AMOUNT_PAID,
    ];
    if (status) {
      if (
        (constants.ELIGIBILITY_FORM_FILLED === status ||
          constants.TOKEN_AMOUNT_PAID === status) &&
        getCurrentAdminId() === constants.ANUP_ID
      ) {
        return false;
      }
      return (
        (currentAdminIsManager() || currentAdminIsSeniorManager()) &&
        statusList.includes(status)
      );
    }
    return !(currentAdminIsManager() || currentAdminIsSeniorManager());
  };

  function getStatusDropdown() {
    return (
      <Stack
        useFlexGap
        flexWrap='wrap'
        gap={1}
        direction='row'
      >
        <CreateAndUpdateOfferLetter
          setOpenOfferLetterModal={setOpenOfferLetterModal}
          setActiveOLInfo={setActiveOLInfo}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
        />

        {shouldAllowEligibilityFormUpdate() &&
          disableStatusUpdateButtonForManagers() && <EligibilityForm />}

        {shouldAllowScreeningCallUpdate() && (
          <Button
            onClick={() => setOpenScheduleMeetingPopup(true)}
            variant='contained'
            color='success'
            size='small'
            sx={{
              height: '2.5em',
            }}
          >
            Update Screenng Call
          </Button>
        )}

        {nextStatusList.map((statusObj, index) => {
          if (disableStatusUpdateButtonForManagers(statusObj.status)) {
            return '';
          }
          return (
            <Tooltip
              disabled={
                !currentAdminIsManagerOrAdminOrMarketing() &&
                statusObj.status === constants.REACTIVATED
              }
              onClick={(e) => {
                handleStatusListChange(statusObj.status);
              }}
              title={statusObj.description}
              placement='top'
              arrow
              TransitionComponent={Zoom}
            >
              {getStatusLabel(
                statusObj.status,
                false,
                {},
                {
                  clickable: true,
                  size: 'medium',
                }
              )}
            </Tooltip>
          );
        })}
      </Stack>
    );
  }

  const shouldShowAssigneeDropdown = () => {
    const { status = '' } = editedFields;
    if (
      status === constants.ASSIGNED &&
      [constants.CREATED, constants.REACTIVATED].includes(currentLead.status)
    ) {
      return true;
    }
    return false;
  };

  function getAssigneeDropdown() {
    const { status = '' } = editedFields;
    return (
      <>
        {shouldShowAssigneeDropdown() && (
          <AdminsAutocompleteDropdown
            disabled={
              status === constants.DROPPED ||
              !currentAdminIsManagerOrAdminOrMarketing()
            }
            label='Assignee'
            value={getFieldValue('assignee')}
            onChangeHandler={(e, newValue) => {
              e.target.name = 'assignee';
              e.target.value = newValue.value;
              handleChange(e);
            }}
            adminOptions={{
              access_type: ['allowed', 'restricted'],
              roles: [
                constants.ADMIN,
                constants.INDEPENDENT_CONTRIBUTOR,
                constants.TRAINEE,
              ],
            }}
            sx={{
              width: '15rem',
            }}
            size='small'
          />
        )}
      </>
    );
  }

  function getStatusConfirmationModal() {
    return (
      <Dialog
        open={openStatusConfirmationModal}
        onClose={() => setOpenStatusConfirmationModal(false)}
      >
        <DialogContent>
          <DialogContentText>
            This action cannot be undone. Are you sure you would like to change
            the status?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setOpenStatusConfirmationModal(false)}>
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              setOpenStatusConfirmationModal(false);
              setStatusUpdateConfirmed(true);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function getJunkConfirmationModal() {
    return (
      <Dialog
        open={showJunkModal}
        onClose={() => {
          setShowJunkModal(false);
        }}
      >
        <DialogContent
          sx={{
            padding: '2rem 1rem 1rem 2rem',
            width: '400px',
          }}
        >
          <DialogContentText fontSize={'larger'}>
            Is this a junk lead?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowJunkModal(false);
              setEditedFields({
                ...editedFields,
                junkLead: 'No',
              });
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              setShowJunkModal(false);
              setEditedFields({
                ...editedFields,
                junkLead: 'Yes',
              });
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function submitHandler() {
    let postData = { ...editedFields };

    if (!postData?.assignee) {
      // because assignee cannot be removed explicitly.
      delete postData.assignee;
    }

    if (postData.status === currentLead.status) {
      delete postData.status;
    }

    if (postData.assignee === currentLead.assignee) {
      delete postData.assignee;
    }

    // if (postData.dropReason && postData?.dropReason !== "") {
    //   postData.status = constants.DROPPED;
    // }

    if (postData?.assignee) {
      const baseStatus = [constants.CREATED, constants.REACTIVATED, ''];

      if (baseStatus.includes(currentLead.status ?? '')) {
        postData.status = constants.ASSIGNED;
      }
    }

    switch (postData?.status) {
      case constants.DROPPED:
        // if ((selectedReasons.primary ?? '') === '') {
        //   setHasError(true);
        //   setErrorMessage(
        //     'Lead cannot be dropped without specifying the reason.'
        //   );
        //   return;
        // }

        // postData.primaryReason = selectedReasons.primary;
        // postData.secondaryReasons = Object.values(selectedReasons.secondary);
        break;

      case constants.ASSIGNED:
        if (!postData?.assignee && !currentLead.assignee) {
          setHasError(true);
          setErrorMessage(
            "Please select an assignee because you've updated the status to 'assigned'."
          );
          return;
        }
        if (!postData?.assignee && currentLead.assignee) {
          postData['assignee'] = currentLead.assignee;
        }
        break;

      case constants.REACTIVATED:
        postData['closed_at'] = null;
        break;

      default:
        break;
    }

    const checkMandatoryFieldsWhenUpdatingStatus = () => {
      const validStatuses = [
        constants.LEAD_QUALIFICATION_DONE,
        constants.NEED_GENERATION_DONE,
        constants.DREAM_SELLING_DONE,
        constants.QUESTIONING_IN_TECH_DONE,
        constants.COMPLETE_PROGRAM_EXPLAINED,
        constants.ELIGIBILITY_FORM_SENT,
        constants.ELIGIBILITY_FORM_FILLED,
        constants.SCREENING_CALL_SCHEDULED,
        constants.SCREENING_CALL_ATTENDED,
        constants.OFFER_LETTER_PAYMENT_LINK_SENT,
        constants.TOKEN_AMOUNT_PAID,
        constants.COMPLETE_AMOUNT_PAID,
      ];

      return validStatuses.includes(postData?.status);
    };

    if (checkMandatoryFieldsWhenUpdatingStatus()) {
      const setFields = [];
      if (!currentLead.experience) {
        setFields.push('experience');
      }
      if (!currentLead.mobile) {
        setFields.push('phone');
      }
      if (!currentLead.graduation_year) {
        setFields.push('graduation year');
      }
      if (!currentLead.email) {
        setFields.push('email');
      }
      if (!currentLead.whatsapp) {
        setFields.push('whatsapp');
      }

      if (setFields.length > 0) {
        setErrorMessage(`Update ${setFields.join(', ')} fields`);
        setHasError(true);
        return;
      }
    }

    if (postData.status === constants.COMPLETE_PROGRAM_EXPLAINED) {
      metadata.total_call_duration = parseInt(metadata.total_call_duration, 10);
      postData['metadata'] = metadata;
    }

    if (postData.status === constants.SCREENING_CALL_SCHEDULED) {
      postData['metadata'] = metadataForScreeningCallSchedule;
    }

    if (postData.status === constants.SCREENING_CALL_ATTENDED) {
      postData['metadata'] = metadataForScreeningCallAttended;
    }

    if (Object.keys(postData).length === 0) {
      return;
    }

    // if (
    //   !salesClouserPaymentsData &&
    //   postData.status === constants.COMPLETE_AMOUNT_PAID
    // ) {
    //   setHasError(true);
    //   setErrorMessage(
    //     'CAP can be marked only after filling complete payment details.'
    //   );
    //   return;
    // }

    axios
      .put(base_url() + '/api/users/' + currentLead.id, postData)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Lead successfully updated');
        if (
          [TOKEN_AMOUNT_PAID, COMPLETE_AMOUNT_PAID].includes(postData.status)
        ) {
          setShowConfetties(true);
        }
        setMetadataForScreeningCallAttended({});
        setMetadataForScreeningCallSchedule({});
        refreshLeadContext();
        getLeadActivitiesMetadata();
        getLeadScheduledActivitiesMetadata();
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't update the lead"
        );
      })
      .finally(() => {
        setStatusUpdateConfirmed(false);
        setEditedFields({});
        setSelectedReasons({
          primary: '',
          secondary: {},
        });
      });
  }

  const refreshLeadContext = () => {
    getCurrentLeadAndUpdateContext(currentLead.id);
    getCurrentLeadStatusActivitiesAndUpdateContext(currentLead.id);
  };

  const handleCloseOfferLetter = (success = false) => {
    if (success) {
      refreshLeadContext();
    }
    setOpenOfferLetterModal(false);
  };

  const showScreeningPopup = () => {
    setOpenScheduleMeetingPopup(true);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      {showEFSBannerStatus || showDnpBanner ? (
        <></>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: shouldAllowEndRecording()
              ? 'space-between'
              : 'flex-end',
          }}
        >
          {shouldAllowEndRecording() && (
            <Button
              onClick={handleOpenRecordingOffModal}
              size='small'
              sx={{
                backgroundColor: 'red',
                color: 'white',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                '&:hover': {
                  backgroundColor: 'red',
                  color: 'white',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                  justifyContent: 'center',
                  textAlign: 'center',
                  padding: '3px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '100%',
                      width: '10px',
                      height: '10px',
                      animation: `${blinkAnimation} 2s infinite`,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  End Screening call
                </Box>
              </Box>
            </Button>
          )}

          <Stack
            spacing={2}
            direction='row'
            justifyContent='flex-end'
            sx={{
              height: '2rem',
            }}
          >
            {getAssigneeDropdown()}
            {getStatusDropdown()}

            {Object.keys(allDropReasons).length > 0 && openDropReasonModal && (
              <DropReasonModal
                {...{
                  openDropReasonModal,
                  setOpenDropReasonModal,
                  reasons: allDropReasons,
                  selectedReasons,
                  setSelectedReasons,
                  handleDropReasonModalClose,
                }}
              />
            )}
          </Stack>
        </Box>
      )}
      <CallDurationMetadataDialog
        updateLeadDetailState={updateLeadDetailState}
        openCompleteProgramExplainedModal={openCompleteProgramExplainedModal}
        setOpenCompleteProgramExplainedModal={
          setOpenCompleteProgramExplainedModal
        }
        metadata={metadata}
        setMetadata={setMetadata}
        setHasError={setHasError}
        setErrorMessage={setErrorMessage}
      />
      {/* // meeting schedule */}
      <ScreeningCallMetaDataDialog
        openRecordingOffModal={openRecordingOffModal}
        setOpenRecordingOffModal={setOpenRecordingOffModal}
        setHasError={setHasError}
        setErrorMessage={setErrorMessage}
        setIsSuccess={setIsSuccess}
        setSuccessMessage={setSuccessMessage}
        leadStatusActivity={leadStatusActivity}
        getLeadActivitiesMetadata={getLeadActivitiesMetadata}
        updateLeadDetailState={updateLeadDetailState}
        refreshLeadContext={refreshLeadContext}
        setMetadataForScreeningCallAttended={
          setMetadataForScreeningCallAttended
        }
        leadScheduledStatusActivity={leadScheduledStatusActivity}
        getLeadScheduledActivitiesMetadata={getLeadScheduledActivitiesMetadata}
      />
      {openScheduleMeetingPopup && (
        <ScheduleMeetingPopUp
          openScheduleMeetingPopup={openScheduleMeetingPopup}
          setOpenScheduleMeetingPopup={setOpenScheduleMeetingPopup}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          leadScheduledStatusActivity={leadScheduledStatusActivity}
          getLeadScheduledActivitiesMetadata={
            getLeadScheduledActivitiesMetadata
          }
          updateLeadDetailState={updateLeadDetailState}
          refreshLeadContext={refreshLeadContext}
          setMetadataForScreeningCallSchedule={
            setMetadataForScreeningCallSchedule
          }
          currentLead={currentLead}
        />
      )}
      <ScreeningCallMetaDataSchedule
        openScreeningScheduleModal={openScreeningScheduleModal}
        setOpenScreeningScheduleModal={setOpenScreeningScheduleModal}
        setHasError={setHasError}
        setErrorMessage={setErrorMessage}
        setIsSuccess={setIsSuccess}
        setSuccessMessage={setSuccessMessage}
        leadScheduledStatusActivity={leadScheduledStatusActivity}
        getLeadScheduledActivitiesMetadata={getLeadScheduledActivitiesMetadata}
        updateLeadDetailState={updateLeadDetailState}
        refreshLeadContext={refreshLeadContext}
        setMetadataForScreeningCallSchedule={
          setMetadataForScreeningCallSchedule
        }
      />
      {showJunkModal && getJunkConfirmationModal()}
      {openStatusConfirmationModal && getStatusConfirmationModal()}
      {openOfferLetterModal && (
        <OfferLetterModal
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          setOpenOfferLetterModal={setOpenOfferLetterModal}
          openOfferLetterModal={openOfferLetterModal}
          activeOLInfo={activeOLInfo}
          refreshLeadContext={refreshLeadContext}
          handleCloseOfferLetter={handleCloseOfferLetter}
        />
      )}
      {openSignupModal && (
        <SignupsModal
          open={openSignupModal}
          handleClose={setOpenSignupModal}
          setFormSubmitted={setSignupModalData}
        />
      )}
    </>
  );
}

export default React.memo(PartialViewLeadEditForm);
